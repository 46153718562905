import React from "react";
import Relation from "../assets/2.png";
import Nurture from "../assets/3.png";
import Manage from "../assets/4.png";
import Skill from "../assets/5.png";
import Begin from "../assets/6.png";
import Tool from "../assets/7.png";
import Banner from "../assets/8.png";
import Pic from "../assets/10.png";

const Triples = () => {
  return (
    <div className="w-full py-1 px-4">
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 bg-emerald-900 rounded-md"
        id="Relation"
      >
        <img className="w-[500px] my-0" src={Relation} alt="/" />
        <div className="flex flex-col text-center text-white mb-8">
          <p className="md:text-4xl sm:text-3xl text-2xl font-bold text-amber-500 mt-[25px]">
            The Problem Runs Deeper…
          </p>
          <h1 className="md:text-xl sm:text-xl text-xl font-bold py-3 mr-2 ml-2">
            Let me also ask you this important question
          </h1>
          <h1 className="md:text-xl sm:text-xl text-xl font-bold py-3 mr-2 ml-2">
            “What is your relationship with money?”
          </h1>
          <p className="mt-[10px] text-xl mr-2">
            Our relationship with money is a result of our subconsciously driven
            behaviours that, in most cases, we are not even aware of. Most of
            these behaviours have their roots in one’s childhood and get
            hardwired as patterns at early ages. They are so deeply ingrained in
            us, they determine how we deal with money as adults. When it comes
            to the relationship with money, people often have unconscious
            patterns, beliefs, and behaviours around money that block them from
            achieving their true financial potential. These challenges are
            fundamentally due to behavioural and emotional issues.
          </p>
          <p className="mt-[15px] text-3xl text-amber-500 font-bold">
            If you could relate to any of the situations listed above, then your
            overwhelm about money is real.
          </p>
        </div>
      </div>
      <div className="text-center mt-[25px] ">
        <a href="https://colossal-crafter-7972.ck.page/84c6de4e3f">
          <button className="bg-emerald-900 w-[350px]  text-2xl  text-amber-500 rounded-md font-bold mb-[50px]  px-1 py-3 ">
            Find Out Your Relationship With Money
          </button>
        </a>
      </div>
      <div className="flex-wrap w-full inline-flex align-center justify-evenly p-5">
        <h1 className="text-center my-9 h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold">
          But I want you to know it’s not your fault.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Your decisions, actions &amp; even inactions are a result of your
          subconsciously driven behaviours.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          You may or may not be aware of the pain that your relationship with
          money is causing. This pain, whether or not in your conscious
          awareness, is not going to stop until you address it head on.
        </h1>
        <div className="flex flex-col justify-evenly items-center">
          <img
            className="inline lg:mr-5 md:mr-0 w-90 h-80"
            src={Nurture}
            alt="/"
          />
        </div>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          The lack of simple &amp; unbiased guidance around skills to manage
          money adds to the problem….
        </h1>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          Managing money &amp; personal finances is an essential life skill
          required by each individual on a day-to-day basis. It is a skill that
          can be learned, but surprisingly no one teaches it. Please be clear I
          am not talking about managing some complex mathematical problem. I am
          talking about simply the basic skills to take the day-to- day
          decisions with your money in such a way that helps you achieve your
          dream life. In most cases we assume someone (either a parent, or
          spouse, or sibling or relative or someone else) will do it for us.
          There is not much unbiased support or guidance available due to which
          people, who have no option of someone else managing their money, learn
          the long &amp; hard way. Traditionally, it was either the LIC agent or
          a relative that most people would look too for guidance. On the flip
          side, now a days there’s information overload, with so much
          conflicting and/or outdated information that it results in NO ACTION.
        </p>
        <h1 className="text-center my-9 h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold">
          The good news is that you have the power to change this by upgrading
          your money mindset &amp; your money skillset.
        </h1>
      </div>
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 bg-emerald-900 rounded-md"
        id="Solution"
      >
        <img className="w-[500px] my-0" src={Manage} alt="/" />
        <div className="flex flex-col text-center text-white mb-8">
          <p className="md:text-4xl sm:text-3xl text-2xl font-bold text-amber-500 mt-[25px]">
            The Solution
          </p>
          <h1 className="md:text-2xl sm:text-2xl text-2xl font-bold py-3 mr-2 ml-2">
            My unique approach in helping you become financially empowered
          </h1>

          <p className=" mr-2 text-xl">
            Become financially empowered and live your life to the fullest by
            taking control of your money &amp; finances using a unique &amp;
            simple approach created by me.
          </p>
          <p className=" mr-2 text-xl">
            You are unique &amp; every individual is unique. Your circumstances,
            preferences, values and the way you wish to live your life is unique
            to you. That’s why the objective of managing your money should be to
            enable you to use money as a tool to design your life in a way
            that&#39;s fulfilling &amp; meaningful to you &amp; in alignment
            with your values.
          </p>
          <div className="text-center mt-[25px]">
            <a href="https://www.facebook.com/groups/504805488298539/">
              <button className="bg-white w-[200px] text-xl  text-emerald-900 rounded-md font-bold mb-[50px] mx-auto px-6 py-3 ">
                Join FB Group
              </button>
            </a>
          </div>
        </div>
      </div>
      <div
        className="flex-wrap w-full inline-flex align-center justify-evenly p-5"
        id="FE"
      >
        <h1 className="text-center my-9 h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold">
          What does it mean to become Financially Empowered at mindset level
          &amp; skill set level?
        </h1>
      </div>
      <div className="flex flex-col justify-evenly items-center">
        <img className="inline lg:mr-5 md:mr-0 w-90 h-70" src={Skill} alt="/" />
      </div>
      <div
        className="max-w-[1240px] mt-[40px] mx-auto grid md:grid-cols-2 bg-emerald-900 rounded-md items-center"
        id="Whom"
      >
        <img className="w-[500px] my-0 " src={Begin} alt="/" />
        <div className="flex flex-col text-center text-white mb-8">
          <p className="md:text-4xl sm:text-3xl text-2xl font-bold text-amber-500 mt-[25px]">
            Who is this for?
          </p>

          <p className="mt-[10px] mr-2 text-2xl">
            It doesn&#39;t matter who you are - a student, a young adult, a
            housewife, a business owner, a salaried person, or even a senior
            citizen; if you have experienced money overwhelm and you value
            living a financially stress free life then this is for you.
          </p>
          <div className="text-center mt-[25px]">
            <a href="https://www.facebook.com/groups/504805488298539/">
              <button className="bg-white w-[200px] text-xl  text-emerald-900 rounded-md font-bold mb-[50px] mx-auto px-6 py-3 ">
                Join FB Group
              </button>
            </a>
          </div>
        </div>
      </div>
      <div className="flex-wrap w-full inline-flex align-center justify-evenly p-5">
        <div className="flex flex-col justify-evenly items-center">
          <img
            className="inline lg:mr-5 md:mr-0 w-90 h-80"
            src={Tool}
            alt="/"
          />
        </div>
        <h1 className="inline-flex align-top my-9 h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold">
          My Vision
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          Financial fitness becomes a way of life in the society and people no
          longer suffer due to lack of financial knowledge &amp; limiting money
          beliefs.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Money conversations no longer are looked at as a taboo, instead they
          become part of every family&#39;s dinner table conversation, coffee
          table conversations among friends, colleagues &amp; amongst everyone
          including children, regardless of their age, gender or any other
          factor.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold">
          My Mission
        </h1>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I am on a mission to empower people &amp; families around the world to
          take control of their financial wellbeing. I help people go from being
          overwhelmed, confused, or clueless about managing their money &amp;
          personal finances to being financially fit and having a good
          relationship with money. This in turn helps them achieve greater
          levels of personal satisfaction, prosperity, peace and fulfilment in
          their life.
        </p>
      </div>
      <div className="text-center mt-[25px]">
        <a href="https://www.facebook.com/groups/504805488298539/">
          <button className="bg-emerald-900 w-[200px] text-xl  text-amber-500 rounded-md font-bold mb-[50px]  px-6 py-3 ">
            Join FB Group
          </button>
        </a>
      </div>
      <div
        className="max-w-[1240px] mt-[40px] mx-auto   bg-emerald-900 rounded-md items-center"
        id="Testimonial"
      >
        <div className=" text-center text-white mb-8 ">
          <p className="md:text-xl sm:text-xl text-2xl font-bold text-amber-500 mt-[25px]">
            How others have benefitted
          </p>

          <p className="mt-[30px] mr-2 text-xl">
            How Kavita has impacted my relationship with money, leaves me lost
            for words. I soon learnt how my limiting beliefs impacted my money
            mindset, my daily habits and hampered my professional and personal
            growth. I can confidently say that I not only feel assured with my
            personal finances but now set audacious financial goals for myself
            that I work toward with defined steps and guidance, thanks to
            Kavita. Highly recommend this journey as it surely had a ripple
            effect on multiple domains of my life.
          </p>
          <p className="ml-[100px] lg:ml-[800px] sm:ml-[800px] text-xl mt-4">
            {" "}
            -Shreya, a digital media professional, Mumbai
          </p>
          <p className="mt-[30px] mr-2 text-xl">
            I thought I knew exactly where I was in terms of inflow and outflow
            but this really helped put everything in perspective and how I
            wasn't aware of the true picture. Secondly assisting in setting a
            clear personal outcome to which I had previously never given
            thought. This will help me set realistic goals for myself that I
            would like to achieve for both myself and my family. I am very
            excited about the prospect of building a financially secure future
            with your guidance.
          </p>
          <p className="ml-[100px] lg:ml-[800px] sm:ml-[800px] text-xl mt-4">
            {" "}
            -Sumi, a business owner, Bangalore
          </p>
          <p className="mt-[30px] mr-2 text-xl">
            It was a complete new perspective about money management. I could
            realise that financial health is basically different stages of a
            continuous journey. It resembled a specialist doctor treating a
            chronic patient.
          </p>
          <p className="ml-[100px] lg:ml-[800px] sm:ml-[800px] text-xl mt-4">
            {" "}
            -Akaash, Coach
          </p>
        </div>
      </div>
      <div className="text-center mt-[25px] ">
        <a href="https://colossal-crafter-7972.ck.page/84c6de4e3f">
          <button className="bg-emerald-900 w-[350px]  text-2xl  text-amber-500 rounded-md font-bold mb-[50px]  py-3 ">
            Know If Money Is Working For You
          </button>
        </a>
      </div>
      <div
        className="flex-wrap w-full inline-flex align-center justify-evenly p-5"
        id="About"
      >
        <h1 className="text-center my-5  h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold">
          Why me?
        </h1>
      </div>
      <div className="flex flex-col justify-evenly items-center">
        <img
          className="inline lg:mr-5 md:mr-0 w-90 h-80 rounded"
          src={Pic}
          alt="/"
        />
      </div>
      <div className="flex-wrap w-full inline-flex align-center justify-evenly p-5">
        <h1 className="inline-flex align-top my-9 lg:w-4/5 md:w-3/4 text-3xl font-bold">
          Who am I?
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I have many different identities. I am an independent woman. I am a
          mother of a young daughter, I am a wife, a daughter, a sister, a
          daughter-in-law, a friend in terms of relationships. I have been a
          student, a young professional, I have been self-employed, I have also
          been in corporate jobs. I live in India. I have also experienced
          setting up my own house and living in London.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I have played many roles in my life and been through different life
          stages. I have understood very deeply &amp; clearly that the way we
          look at money evolves with each life stage and each role that we play.
          Today I am who I am because I bring all these experiences along with
          me. Tomorrow, I will still be a better version of who I am today.
        </h1>
        <h1 className="inline-flex align-top my-9 lg:w-4/5 md:w-3/4 text-3xl font-bold">
          My Qualifications
        </h1>
        <div className="flex-wrap w-full items-center ml-[60px] justify-evenly p-5">
          <ul className="list-disc text-lg font-bold ">
            <li>Chartered Accountant (CA)</li>
            <li>
              Certified Money Coach (CMC®) from The Money Coaching Institute in
              the USA which is the pioneer in the field of Behavioral Money
              Coaching globally. The only CMC® in India.
            </li>
            <li>Qualified Personal Finance Professional (QPFP®)</li>
          </ul>
        </div>
        <h1 className="inline-flex align-top my-9 lg:w-4/5 md:w-3/4 text-3xl font-bold">
          My career journey
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          A seasoned investment professional with close to two decades of
          experience in Financial Services industry.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I have a uniquely diverse &amp; extensive background in Investments
          encompassing multiple areas including Investment Research, Analysis
          &amp; Reporting, Investments Management, Investment Directing,
          Business Liaising, New Product development &amp; launch, Investment
          Analytics.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I currently head the Investments Research &amp; Analytics for the
          Indian operations of Guardian Life, a US based leading Insurance
          player. Prior to this, I have worked with organisations such as
          Fidelity International, Macquarie US, Moody’s, and Systematix Shares
          &amp; Stocks, while being based out of Gurgaon, London &amp; Mumbai. I
          have held varied Investments roles spanning across various asset
          classes (Equities, Private Equity, Credit, Multi-Asset) catering to
          institutional investors globally, and focused on various international
          markets &amp; geographies. I have handled multi-site as well as cross
          functional teams and have worked closely with stakeholders across the
          globe. I have evolved through my diverse roles as an Investments
          Professional in the corporate world. I continue to thoroughly enjoy my
          corporate role &amp; responsibilities, and aspire to be a better
          version of myself every single day.
        </h1>
        <h1 className="inline-flex align-top my-9 lg:w-4/5 md:w-3/4 text-3xl font-bold">
          My Money Story
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I come from a marwari business family. As kids we were kept away from
          money conversations in the family during our growing up years.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          I went on to become a Chartered Accountant and made a career as an
          Investments Professional and took on roles that deal with the
          sophisticated side of investments research &amp; analysis, working
          with sophisticated institutional clients, portfolio managers etc.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          My career has definitely been exciting and I have enjoyed moving from
          strength to strength in different investment roles. But I would see
          the contrast in the way people around me among my family &amp; friends
          managed their money or the lack of information &amp; guidance on this
          front. Was I any different? Well the truth is when it came to managing
          personal finances, I wasn&#39;t very different from the rest of them.
          Yes, that&#39;s true! No one teaches you how to manage your own money
          - It is not taught in schools, colleges or even professional courses
          such as CA or MBA. So as a finance professional with one or these
          degrees you may learn taxation, investments research, corporate
          finance; but personal finance is not even taught to finance
          professionals.
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          The only way I was different from the folks around me was when someone
          offered to sell some policies &amp; financial products to me, I could
          do my own research, run some calculations in excel (sometimes even
          elaborate calculations) to analyse if it was a good offering or not.
          To my surprise when I ran the calculations, none of the so-called
          great products offered ever qualified to be good investments. So I
          definitely got saved from mis-selling But there&#39;s much more to
          managing your money than just that. I did work on other aspects as
          well, made some mistakes in the process, learnt from them and got some
          pieces of the personal finance jigsaw puzzle right. Even then I did
          not find a holistic end to end solution for years until there came a
          time when I said to myself that managing personal finances well and
          having a financially well planned life cannot be so difficult and I
          decided to make a conscious committed effort to decode it. This took
          me on a journey that most definitely has been a transformative &amp;
          evolutionary process for myself. Today I have achieved Financial
          Freedom and have a much deeper understanding of money on psychological
          as well as practical levels.
        </h1>
        <h1 className="inline-flex align-top my-9 lg:w-4/5 md:w-3/4 text-3xl font-bold">
          My Passion Project – “The Money Coach (TMC)”
        </h1>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          Over my long career in the Investments world, I saw the contrast where
          on one hand I worked with portfolio managers &amp; sophisticated
          institutional investors across the globe; whereas on the other hand I
          saw that when it comes to managing own money &amp; finances, most
          people really struggle and due to that their loved ones also suffer. I
          believe that they don’t deserve to or need to struggle but there is
          very little help available. I feel very strongly about filling this
          gap through increased education, awareness, and empowerment, so that
          people can be financially fit and are able to create a financially
          stress-free life for themselves and their loved ones. Being most
          ardent about making a difference on this front, I started my passion
          project “The Money Coach (TMC)” to fill this gap. My quest for
          decoding how to live a financially well planned &amp; stress free life
          and helping people with the same has led me on a journey with many
          milestones.
        </h1>
        <div className="flex-wrap w-full inline-flex ml-[60px] align-center justify-evenly p-5">
          <ul className="list-disc text-lg">
            <li>
              One of the most important aspects of this journey has been
              understanding the psychological aspects of managing money .
            </li>

            <li>
              I have worked on the theories of most renowned money gurus across
              the globe and filtered &amp; imbibed their teachings to fit my
              unique approach
            </li>
            <li>I continue to learn &amp; evolve on this front</li>
          </ul>
        </div>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          After going into the depth of different aspects and pieces of the
          money management jigsaw puzzle, I have created a simple step by step
          approach that gives you just the right information you need to go from
          where you are to being financially fit and be on the path to financial
          freedom &amp; abundance.
        </h1>
        <h1 className="inline-flex align-top my-9 lg:w-4/5 md:w-3/4 text-3xl font-bold">
          Committed to changing the way most people deal with, think about, and
          manage or rather mis-manage money.
        </h1>
      </div>
      <div className="text-center mt-[25px]">
        <a href="https://www.facebook.com/groups/504805488298539/">
          <button className="bg-emerald-900 w-[200px] text-xl  text-amber-500 rounded-md font-bold mb-[50px] mx-auto px-6 py-3 ">
            Join FB Group
          </button>
        </a>
      </div>
      <div className="max-w-[1640px] mx-auto ">
        <div className="justify-evenly relative ">
          <img
            className="w-full h-[200px] lg:h-[400px]  rounded-xl"
            src={Banner}
            alt="/"
          />
        </div>
      </div>
    </div>
  );
};

export default Triples;
