import React from "react";
import Green from "../assets/green.jpg";

const Hero = () => {
  return (
    <div className="max-w-[1640px] mx-auto p-4" id="Hero">
      <div className=" relative ">
        {/* Overlay */}
        <div className="absolute w-full h-full text-gray-200 max-h-[650px] bg-black/40 flex flex-col justify-center rounded-xl">
          <h1 className="px-4 text-amber-500 font-bold text-3xl lg:text-4xl text-center">
            Are you struggling with managing your money &amp; personal finances?
          </h1>
          <h4 className="px-4 lg:text-xl text-xl text-center mt-6">
            Are you using your money efficiently to create the life you dream
            of?
          </h4>{" "}
          <h4 className="px-4 lg:text-xl text-xl text-center mt-4">
            {" "}
            Are you able to plan money for the future of your loved ones?
          </h4>
          <h4 className="px-4 lg:text-xl text-xl text-center mt-4">
            Are your finances well planned to take the shock of any unexpected
            events?
          </h4>{" "}
          <span className="px-4 lg:text-xl text-xl text-center ">OR </span>
          <h4 className="px-4 lg:text-xl text-xl text-center mt-1">
            Are you still stuck with the notion that you will do all this ONLY
            WHEN you have a lot of money?
          </h4>
          <div className="text-center mt-[30px]">
            <a href="https://colossal-crafter-7972.ck.page/84c6de4e3f">
              <button className="bg-white w-[200px] text-lg  text-emerald-900 rounded-md font-bold mb-[50px] mx-auto px-6 py-3 ">
                Take The Money Quiz
              </button>
            </a>
          </div>
        </div>

        <img
          className="w-full h-[650px] object-cover rounded-xl"
          src={Green}
          alt="/"
        />
      </div>
    </div>
  );
};

export default Hero;
