import React from "react";

const News = () => {
  return (
    <div className="h-fitcontent w-full flex-row my-5">
      <div className="flex-wrap w-full inline-flex align-center justify-evenly p-5">
        <h1
          className="text-center mb-[50px] my-5  h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold"
          id="Problem"
        >
          Money Overwhelm
        </h1>
        <div className="flex flex-col justify-evenly items-center">
          <img
            className="inline lg:mr-5 md:mr-0 w-90 h-80"
            src="https://images.pexels.com/photos/7680366/pexels-photo-7680366.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
            alt="/"
          />
        </div>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          We all have experienced money overwhelm at one moment or another, yet
          most people have not heard of it. Why is that? Many of us tend to hide
          our money overwhelm not only from others but also from ourselves and
          live in denial. Why? Because we surrender our power to money.
        </p>
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Do you find yourself in any of these situations?
        </h1>

        <ul className="list-disc text-xl">
          <li>
            Can’t hold on to money, it continuously slips right through your
            fingers
          </li>
          <li>
            Anxious that if you lose your job then how will you & your family go
            on with life without getting majorly affected; Not prepared to deal
            with financial impact of unexpected emergencies
          </li>
          <li>
            Investment doesn’t come naturally to you, as you would rather keep
            money under mattresses and in secret places or at best in a FD
          </li>
          <li>
            Living in financial secrecy, fearing what others “think” of you
          </li>
          <li>
            Focused on getting higher return from investments or saving taxes,
            without bothering to have a plan about how that money will help you
            live the life that you desire
          </li>
          <li>
            Taking the ostrich approach and avoiding managing your money &
            taking financial decisions pretending this will not impact your life
            in any way
          </li>
        </ul>

        <h1 className="mt-[50px] font-bold text-3xl mb-[30px] text-center">
          Even signs as common as these indicate Money Overwhelm.
        </h1>
      </div>
    </div>
  );
};

export default News;
