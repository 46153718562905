import React, { Fragment } from "react";
import Analytics from "./components/Analytics";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import News from "./components/News";
import Triples from "./components/Triples";
import Footer from "./components/Footer"
import Policies from "./components/policies"
import PolicyNav from "./components/PolicyNav"
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
} from "react-router-dom";

function App() {
  return (
    <div>
      <Router>
        <Routes>
          <Route
            path="/"
            element={
              <Fragment>
                <Navbar />
                <Hero />
                <Analytics />
                <News />
                <Triples />

                <Footer />
              </Fragment>
            }
          />
           <Route
            path="/policies"
            element={
              <Fragment>
                 <PolicyNav />
                <Policies />
                <Footer />
              </Fragment>
            }
          />
          {/* <Route path="/about" element={<About />}>
            <Route path="team" element={<Team />} />
            <Route path="company" element={<Company />} />
          </Route>
          <Route path="/contact" element={<Contact />} /> */}
        </Routes>
      </Router>
      {/* <Navbar />
      <Hero />
      <Analytics />
      <News />
      <Triples />
      
      <Footer /> */}
    </div>
  );
}

export default App;
