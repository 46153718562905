import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

const News = () => {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  return (
    <div className="h-fitcontent w-full flex-row my-5">
      <div className="flex-wrap w-full inline-flex align-center justify-evenly p-5">
        <h1
          className="text-center mb-[50px] my-5  h-fit lg:w-4/5 md:w-3/4 text-3xl font-bold"
          id="Problem"
        >
          Copyright © 2024 Kavita Dhamani | All Rights Reserved
        </h1>

        {/* <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          We all have experienced money overwhelm at one moment or another, yet
          most people have not heard of it. Why is that? Many of us tend to hide
          our money overwhelm not only from others but also from ourselves and
          live in denial. Why? Because we surrender our power to money.
        </p> */}
        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Policies Page
        </h1>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Disclaimer
        </p>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          The information provided are for coaching & educational purposes only
          and should not be construed as investment advice. We make no
          warranties or representations, express or implied, on products and
          services offered through the platform. Past performance is not
          indicative of future returns. Please consider your specific investment
          requirements, risk tolerance, goal, investment time frame, risk and
          reward as well as the cost associated with the investment before
          investing, or designing a portfolio that suits your needs. Performance
          and returns of any investment portfolio can neither be predicted nor
          guaranteed. Investment in securities market is subject to market
          risks, read all the related documents carefully before investing.
        </p>

        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Terms & Conditions
        </h1>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Last updated: August 16, 2024
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl">
          For the purpose of these Terms and Conditions, The term "we", "us",
          "our" used anywhere on this page shall mean BWealthy, whose
          registered/operational office is 918, Heights, The Retreat, South City
          I, Block C, Sector 41 Gurgaon HARYANA 122002. "you", “your”, "user",
          “visitor” shall mean any natural or legal person who is visiting our
          website and/or agreed to purchase from us.
        </p>
        <p className="inline-flex align-top my-5   h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Your use of the website and/or purchase from us are governed by
          following Terms and Conditions:
        </p>
        <ul className="list-disc text-xl my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          <li>
            The content of the pages of this website is subject to change
            without notice.
          </li>
          <li>
            Neither we nor any third parties provide any warranty or guarantee
            as to the accuracy, timeliness, performance, completeness or
            suitability of the information and materials found or offered on
            this website for any particular purpose. You acknowledge that such
            information and materials may contain inaccuracies or errors and we
            expressly exclude liability for any such inaccuracies or errors to
            the fullest extent permitted by law.
          </li>
          <li>
            Your use of any information or materials on our website and/or
            product pages is entirely at your own risk, for which we shall not
            be liable. It shall be your own responsibility to ensure that any
            products, services or information available through our website
            and/or product pages meet your specific requirements.
          </li>
          <li>
            Our website contains material which is owned by or licensed to us.
            This material includes, but are not limited to, the design, layout,
            look, appearance and graphics. Reproduction is prohibited other than
            in accordance with the copyright notice, which forms part of these
            terms and conditions.
          </li>
          <li>
            All trademarks reproduced in our website which are not the property
            of, or licensed to, the operator are acknowledged on the website.
          </li>
          <li>
            Unauthorized use of information provided by us shall give rise to a
            claim for damages and/or be a criminal offense.
          </li>
          <li>
            From time to time our website may also include links to other
            websites. These links are provided for your convenience to provide
            further information.
          </li>
          <li>
            You may not create a link to our website from another website or
            document without BWealthy’s prior written consent.
          </li>
          <li>
            Any dispute arising out of use of our website and/or purchase with
            us and/or any engagement with us is subject to the laws of India.
          </li>
          <li>
            We, shall be under no liability whatsoever in respect of any loss or
            damage arising directly or indirectly out of the decline of
            authorization for any Transaction, on Account of the Cardholder
            having exceeded the preset limit mutually agreed by us with our
            acquiring bank from time to time.
          </li>
        </ul>

        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Cancellation & Refund Policy
        </h1>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Last updated: August 16, 2024
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          No cancellations & Refunds are entertained
        </p>

        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Shipping & Delivery Policy
        </h1>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Last updated: August 16, 2024
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Shipping is not applicable for business.
        </p>

        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Privacy Policy
        </h1>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Last updated: August 16, 2024
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          This Privacy Policy describes Our policies and procedures on the
          collection, use and disclosure of Your information when You use the
          Service and tells You about Your privacy rights and how the law
          protects You.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          We use Your Personal data to provide and improve the Service. By using
          the Service, You agree to the collection and use of information in
          accordance with this Privacy Policy.
        </p>

        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Interpretation and Definitions
        </h1>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Interpretation
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The words of which the initial letter is capitalized have meanings
          defined under the following conditions. The following definitions
          shall have the same meaning regardless of whether they appear in
          singular or in plural.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Definitions
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          For the purposes of this Privacy Policy:
        </p>

        <ul className="list-disc text-xl my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          <li>
            Account means a unique account created for You to access our Service
            or parts of our Service.
          </li>
          <li>
            Company (referred to as either “the Company”, “We”, “Us” or “Our” in
            this Agreement) refers to BWealthy.
          </li>
          <li>
            Cookies are small files that are placed on Your computer, mobile
            device or any other device by a website, containing the details of
            Your browsing history on that website among its many uses.
          </li>
          <li>Country refers to: India</li>
          <li>
            Device means any device that can access the Service such as a
            computer, a cellphone or a digital tablet.
          </li>
          <li>
            Personal Data is any information that relates to an identified or
            identifiable individual.
          </li>
          <li>Service refers to the Website.</li>
          <li>
            Service Provider means any natural or legal person who processes the
            data on behalf of the Company. It refers to third-party companies or
            individuals employed by the Company to facilitate the Service, to
            provide the Service on behalf of the Company, to perform services
            related to the Service or to assist the Company in analyzing how the
            Service is used.
          </li>
          <li>
            Third-party Social Media Service refers to any website or any social
            network website through which a User can log in or create an account
            to use the Service.
          </li>
          <li>
            Usage Data refers to data collected automatically, either generated
            by the use of the Service or from the Service infrastructure itself
            (for example, the duration of a page visit).
          </li>
          <li>Website refers to www.kavitadhamani.com</li>
          <li>
            You means the individual accessing or using the Service, or the
            company, or other legal entity on behalf of which such individual is
            accessing or using the Service, as applicable.
          </li>
        </ul>

        {/* <h1 className="mt-[50px] font-bold text-3xl mb-[30px] text-center">
          Even signs as common as these indicate Money Overwhelm.
        </h1> */}

        <h1 className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-2xl font-bold">
          Collecting and Using Your Personal Data
        </h1>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Types of Data Collected
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Personal Data
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          While using Our Service, We may ask You to provide Us with certain
          personally identifiable information that can be used to contact or
          identify You. Personally identifiable information may include, but is
          not limited to:
        </p>
        <ul className="list-disc text-xl my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          <li>Usage Data</li>
        </ul>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Usage Data
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Usage Data is collected automatically when using the Service.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Usage Data may include information such as Your Device’s Internet
          Protocol address (e.g. IP address), browser type, browser version, the
          pages of our Service that You visit, the time and date of Your visit,
          the time spent on those pages, unique device identifiers and other
          diagnostic data.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl">
          When You access the Service by or through a mobile device, We may
          collect certain information automatically, including, but not limited
          to, the type of mobile device You use, Your mobile device unique ID,
          the IP address of Your mobile device, Your mobile operating system,
          the type of mobile Internet browser You use, unique device identifiers
          and other diagnostic data.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          We may also collect information that Your browser sends whenever You
          visit our Service or when You access the Service by or through a
          mobile device.
        </p>
        <p className="inline-flex align-top  my-5   h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Use of Your Personal Data
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The Company may use Personal Data for the following purposes:
        </p>

        <ul className="list-disc text-xl my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          <li>
            To provide and maintain our Service, including to monitor the usage
            of our Service.
          </li>
          <li>
            For the performance of a contract: the development, compliance and
            undertaking of the purchase contract for the products, items or
            services You have purchased or of any other contract with Us through
            the Service.
          </li>
          <li>
            To contact You: To contact You by email, telephone calls, SMS, or
            other equivalent forms of electronic communication, such as a mobile
            application’s push notifications regarding updates or informative
            communications related to the functionalities, products or
            contracted services, including the security updates, when necessary
            or reasonable for their implementation.
          </li>
          <li>
            To provide You with news, special offers and general information
            about other goods, services and events which we offer that are
            similar to those that you have already purchased or enquired about
            unless You have opted not to receive such information.
          </li>
          <li>
            To manage Your requests: To attend and manage Your requests to Us.
          </li>
          <li>
            For business transfers: We may use Your information to evaluate or
            conduct a merger, divestiture, restructuring, reorganization,
            dissolution, or other sale or transfer of some or all of Our assets,
            whether as a going concern or as part of bankruptcy, liquidation, or
            similar proceeding, in which Personal Data held by Us about our
            Service users is among the assets transferred.
          </li>
          <li>
            For other purposes: We may use Your information for other purposes,
            such as data analysis, identifying usage trends, determining the
            effectiveness of our promotional campaigns and to evaluate and
            improve our Service, products, services, marketing and your
            experience.
          </li>
        </ul>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          We do not sell, trade, or otherwise exploit Your personal-identifiable
          information to others. Limited to the purposes stated hereinabove, we
          may share generic aggregated demographic information not linked to any
          personal-identifiable information regarding visitors and Users with
          our business partners and trusted affiliates.
        </p>

        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Retention of Your Personal Data
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The Company will retain Your Personal Data only for as long as is
          necessary for the purposes set out in this Privacy Policy. We will
          retain and use Your Personal Data to the extent necessary to comply
          with our legal obligations (for example, if we are required to retain
          your data to comply with applicable laws), resolve disputes, and
          enforce our legal agreements and policies.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The Company will also retain Usage Data for internal analysis
          purposes. Usage Data is generally retained for a shorter period of
          time, except when this data is used to strengthen the security or to
          improve the functionality of Our Service, or We are legally obligated
          to retain this data for longer time periods.
        </p>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Transfer of Your Personal Data
        </p>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Your information, including Personal Data, is processed at the
          Company’s operating offices and in any other places where the parties
          involved in the processing are located. It means that this information
          may be transferred to — and maintained on — computers located outside
          of Your state, province, country or other governmental jurisdiction
          where the data protection laws may differ than those from Your
          jurisdiction.
        </p>

        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Your consent to this Privacy Policy followed by Your submission of
          such information represents Your agreement to that transfer.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The Company will take all steps reasonably necessary to ensure that
          Your data is treated securely and in accordance with this Privacy
          Policy and no transfer of Your Personal Data will take place to an
          organization or a country unless there are adequate controls in place
          including the security of Your data and other personal information.
        </p>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Business Transactions
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          If the Company is involved in a merger, acquisition or asset sale,
          Your Personal Data may be transferred. We will provide notice before
          Your Personal Data is transferred and becomes subject to a different
          Privacy Policy.
        </p>

        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Law enforcement
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Under certain circumstances, the Company may be required to disclose
          Your Personal Data if required to do so by law or in response to valid
          requests by public authorities (e.g. a court or a government agency).
        </p>

        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Other legal requirements
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The Company may disclose Your Personal Data in the good faith belief
          that such action is necessary to:
        </p>

        <ul className="list-disc text-xl my-5  h-fit lg:w-4/5 md:w-3/4 text-xl">
          <li>Comply with a legal obligation</li>
          <li>Protect and defend the rights or property of the Company</li>
          <li>
            Prevent or investigate possible wrongdoing in connection with the
            Service
          </li>
          <li>
            Protect the personal safety of Users of the Service or the public
          </li>
          <li>Protect against legal liability</li>
        </ul>

        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Security of Your Personal Data
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          The security of Your Personal Data is important to Us, but remember
          that no method of transmission over the Internet, or method of
          electronic storage is 100% secure. While We strive to use commercially
          acceptable means to protect Your Personal Data, We cannot guarantee
          its absolute security.
        </p>

        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Links to Other Websites
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          Our Service may contain links to other websites that are not operated
          by Us. If You click on a third party link, You will be directed to
          that third party’s site. We strongly advise You to review the Privacy
          Policy of every site You visit.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          We have no control over and assume no responsibility for the content,
          privacy policies or practices of any third party sites or services.
        </p>

        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Changes to this Privacy Policy
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          We may update Our Privacy Policy from time to time. We will notify You
          of any changes by posting the new Privacy Policy on this page.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          We will let You know via email and/or a prominent notice on Our
          Service, prior to the change becoming effective and update the “Last
          updated” date at the top of this Privacy Policy.
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          You are advised to review this Privacy Policy periodically for any
          changes. Changes to this Privacy Policy are effective when they are
          posted on this page.
        </p>
        <p className="inline-flex align-top my-5  h-fit lg:w-4/5 md:w-3/4 text-xl font-bold">
          Contact Us
        </p>
        <p className="inline-flex align-top  h-fit lg:w-4/5 md:w-3/4 text-xl ">
          If you have any questions, you can contact us at
          info@kavitadhamani.com
        </p>
      </div>
    </div>
  );
};

export default News;
