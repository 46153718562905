import React from "react";
import { Link } from "react-scroll";
import { useNavigate } from "react-router-dom";

import {
  FaDribbbleSquare,
  FaFacebookSquare,
  FaGithubSquare,
  FaInstagram,
  FaTwitterSquare,
  FaLinkedin
} from "react-icons/fa";

const Footer = () => {
  const navigate = useNavigate()
  return (
    <div
      className="max-w-[1240px] mx-auto py-16 px-4 grid lg:grid-cols-3 gap-8 bg-emerald-800 text-black"
      id="Contact"
    >
      <div className="text-white">
        <h1 className="w-full text-3xl font-bold text-amber-500">
          The Money Coach
        </h1>
        <p className="py-4">
          Become financially empowered and live your life to the fullest by
          taking control of your money &amp; finances using a unique &amp;
          simple approach created by me.
        </p>
        <div className="flex justify-between w-[20%] md:w-[20%] my-6">
          <a target="__blank" href="https://www.facebook.com/kavitadhamani.themoneycoach/">
            <FaFacebookSquare size={30} />
          </a>
          <a target="__blank" href="https://www.instagram.com/kavitadhamani/">
            <FaInstagram size={30} />
          </a>
          <a target="__blank" href="https://www.linkedin.com/in/kavita-dhamani?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app">
            <FaLinkedin size={30} />
          </a>
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6 text-white">
        <div>
          <Link to="Hero" spy={true} smooth={true} offset={-100} duration={500}>
            <h6 className=" text-white ">The Money Struggle</h6>
          </Link>
          <ul>
            <Link
              to="Working"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              {" "}
              <li className="py-7 text-base">Money & you</li>
            </Link>
            <Link
              to="Problem"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <li className="py-2 text-base">Money Overwhelm</li>
            </Link>
          </ul>
        </div>
        <div>
          <Link
            to="Relation"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            <h6 className=" text-white ">Relationship With Money</h6>
          </Link>
          <ul>
            <Link
              to="Solution"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <li className="py-7 text-base">The Solution</li>
            </Link>
            <Link
              to="Testimonial"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <li className="py-2 text-base">Testimonials</li>
            </Link>
            <a href="https://forms.gle/gZcxmzVsmJsXPS9JA">
              <li className="py-2 text-base">Contact Us</li>
            </a>
            <a onClick={()=>navigate("/policies")}>
              <li className="py-2 text-base">Policies</li>
            </a>
           
          </ul>
        </div>
        <div className="ml-3">
          <Link to="FE" spy={true} smooth={true} offset={-100} duration={500}>
            <h6 className=" text-white ">Financially Empowered</h6>
          </Link>
          <ul>
            <Link
              to="Whom"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <li className="py-7 text-base">Who is this for?</li>
            </Link>

            <Link
              to="About"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
            >
              <li className="py-2 text-base">Why Me?</li>
            </Link>
            <a href="https://colossal-crafter-7972.ck.page/84c6de4e3f">
              <li className="py-5 text-base">Take Money Quiz</li>
            </a>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Footer;
