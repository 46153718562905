import React from "react";
import Money from "../assets/1.png";

const Analytics = () => {
  return (
    <div className="w-full py-1 px-4">
      <div>
        <h1 className="text-center text-2xl font-bold mt-[50px] mb-[50px]">
          Take control of your money now &amp; make your money work for you
          without any prior finance knowledge
        </h1>
        <div className="text-center">
          <a href="https://www.facebook.com/groups/504805488298539/">
            <button className="bg-emerald-900 w-[200px] text-lg  text-amber-500 rounded-md font-bold mb-[50px] mx-auto px-6 py-3 ">
              Join FB Group
            </button>
          </a>
        </div>
      </div>
      <div
        className="max-w-[1240px] mx-auto grid md:grid-cols-2 bg-emerald-900 rounded-md "
        id="Working"
      >
        <img className="w-[500px]  my-0" src={Money} alt="/" />
        <div className="flex flex-col justify-center text-center mr-5">
          <p className="text-white text-xl mt-1 mb-5">
            &quot;Have you wished to go from being confused, clueless or
            avoidant about managing your finances to being financially fit? What
            if you could get a simple approach that doesn’t stop at financial
            health but also makes it easy for you to create wealth? What if you
            could lay the foundation to achieve financial freedom &amp;
            abundance, with no prior finance knowledge?&quot;
          </p>
          <h1 className="text-center text-amber-500 text-3xl font-bold mt-[40px] mb-[40px]">
            But does thinking about Money management overwhelm you?
          </h1>
          <div className="text-center mt-[15px]">
            <a href="https://colossal-crafter-7972.ck.page/84c6de4e3f">
              <button className="bg-white w-[200px] text-lg  text-emerald-900 rounded-md font-bold mb-[50px] mx-auto px-6 py-3 ">
                Know If Money Is Working For You
              </button>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Analytics;
