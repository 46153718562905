import React, { useState } from "react";
import { AiOutlineClose, AiOutlineMenu } from "react-icons/ai";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  useNavigate,
  Outlet,
} from "react-router-dom";
const Navbar = () => {
  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };
  return (
    <nav className="">
      <div className="flex  justify-between items-center h-15 max-w-[1240px] mx-auto px-4  ">
        <img src="./images/TMC2.png" className="w-20 " alt="/" />
        <ul className="hidden md:flex text-xl">
          <Link to="/" spy={true} smooth={true} offset={-100} duration={500}>
            <li className="p-5">Home</li>
          </Link>
          <Link
            to="/"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            <li className="p-5">The Problem</li>
          </Link>
          <Link
             to="/"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            <li className="p-5">The Solution</li>
          </Link>
          <Link  to="/" spy={true} smooth={true} offset={-100} duration={500}>
            <li className="p-5">For Whom</li>
          </Link>
          <Link
           to="/"
            spy={true}
            smooth={true}
            offset={-100}
            duration={500}
          >
            <li className="p-5">About</li>
          </Link>
          <a href="https://forms.gle/gZcxmzVsmJsXPS9JA">
            <li className="p-5">Contact</li>
          </a>
        </ul>
        <div onClick={handleNav} className="block md:hidden">
          {nav ? <AiOutlineClose size={30} /> : <AiOutlineMenu size={30} />}
        </div>
        <div
          className={
            nav
              ? "fixed left-0 top-0 w-[60%] h-full border-r border-r-gray-900 bg-[#f9fafb] ease-in-out duration-500 z-10"
              : "ease-in-out duration-500 fixed left-[-100%] w-full h-screen z-10 top-0"
          }
        >
          <img
            src="./images/TMC2.png"
            className="w-20 h-30 mx-4 mt-2"
            alt="/"
          />
          <ul className="uppercase p-4">
            <Link
              to="/"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={handleNav}
            >
              <li className="p-4 border-b border-gray-600">Home</li>
            </Link>
            <Link
              to="/"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={handleNav}
            >
              <li className="p-4 border-b border-gray-600">The Problem</li>
            </Link>
            <Link
               to="/"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={handleNav}
            >
              <li className="p-4 border-b border-gray-600">The Solution</li>
            </Link>
            <Link
               to="/"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={handleNav}
            >
              <li className="p-4 border-b border-gray-600">For Whom</li>
            </Link>
            <Link
              to="/"
              spy={true}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={handleNav}
            >
              <li className="p-4 border-b border-gray-600">About</li>
            </Link>
            <a href="https://forms.gle/gZcxmzVsmJsXPS9JA" onClick={handleNav}>
              <li className="p-4">Contact</li>
            </a>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
